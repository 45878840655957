<template>
  <section class="content">
    <div class="card card-info">
      <div class="card-header">
        <h3 class="card-title"></h3>
      </div>
      <div class="card-body">
          <div class="row">
            <div class="col-9">
                <div class="row form-horizontal">
                    <div class="col-auto">
                        <label class="control-label my-2 ml-2">
                            <h6>Filter Pencarian</h6>
                        </label>
                    </div>
                    <div class="col-auto mb-2">
                    </div>
                    <div class="col-auto mb-2">
                        <div class="input-group">
                            <input type="text" ref="daterange" class="form-control" style="border-right: 0" />
                            <div class="input-group-append">
                                <div class="input-group-text" style="background-color: #fff">
                                    <span><span class="fa fa-calendar"></span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto mb-2">
                        <!-- <button class="btn dropdown-icon  dropdown-toggle" v-bind:class="btnFilter"
                            @click="selectStatus">Status </button> -->
                    </div>
                    <div class="col-auto mb-2">
                        <select class="form-control" @change="statusFilter">
                            <option value="" selected disabled>Status</option>
                            <option value="0">Belum Rekon</option>
                            <option value="1">Sudah Rekon</option>
                            <option value="2">Semua</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-3 text-right">
                <div class="btn-group" ref="toolbar">
                    <button type="button" data-action="read" class="btn btn-default"><i
                            class="fa fa-sync-alt"></i></button>
                    <button type="button" :disabled="processing" v-on:click="downloadXlsx"
                        data-action="export-xlsx" class="btn btn-default"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
                </div>
            </div>
        </div>
        <div class="table-responsive">
          <table class="table table-hover" ref="tblrekonsiliasi" id="tblrekonsiliasi">
            <thead>
              <tr>
                <th>TANGGAL REKONSILIASI</th>
                <th>TANGGAL TERKIRIM</th>
                <th>AWB</th>
                <th>TINDAKAN</th>
                <th>SHIPPER</th>
                <th>NILAI COD</th>
                <th>NILAI ONGKIR</th>
                <th>ITEM VALUE</th>
                <th>PERCENT CASHBACK</th>
                <th>PERCENT SHIPPER</th>
                <th>INSURANCE FEE</th>
                <th>GRAND TOTAL</th>
                <th>ORDER ID</th>
              </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  nama: "Daftar Rekonsiliasi",
  data() {
    return {
      errors: [],
      method: "",
      //   form: {
      //   },
      roles: "",
      role_id: "",
      selectedCount: 0,
      filter: {},
      dateStart: '',
      dateEnd: '',
      dt1: moment().startOf("month"),
      dt2: moment(),
      processing: false
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
    this.toPrice();

    this.dt1 = moment(this.filter.dt1);
    this.dt2 = moment(this.filter.dt2);

    this.dateStart = this.dt1.format('YYYY-MM-DD');
    this.dateEnd = this.dt2.format('YYYY-MM-DD');
  },
  components: {
        vSelect,
    },
  methods: {
    onDate() {
        this.tbl.api().ajax.reload();
    },
    toPrice(value) {
      let total = value;
      let reverse = total.toString().split("").reverse().join(""),
        ribuan = reverse.match(/\d{1,3}/g);
      ribuan = ribuan.join(".").split("").reverse().join("");
      return ribuan;
    },
    statusFilter(event) {
        this.filter.status = event.target.value;
        this.tbl.api().ajax.reload();
    },
    handleClick(e) {
      if (e.target.matches("button")) {
        if (e.target.dataset.action == "confirm") {
          console.log(e.target.dataset.id);
          Swal.fire({
            title: "Rekonsiliasi?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
          }).then((result) => {
            if (result.isConfirmed) {
              authFetch("/payment/rekonsiliasi/" + e.target.dataset.id, {
                method: "PUT",
                body: "id=" + e.target.dataset.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.tbl.api().ajax.reload(null, false);
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                    //this.tbl.api().ajax.reload(null, false);
                  }
                });
            }
          });
        }
        console.log(e.target);
        return false;
      }
    },
    downloadXlsx: function (e) {
        const self = this;
        self.processing = true;

        var table = $('#tblrekonsiliasi').DataTable();
        var length = table.page.info().recordsTotal;
        
        if (length > 20000) {
            Swal.fire("Proses gagal", `Maksimal data rekonsiliasi sebanyak kurang lebih 20.000 data.`, "error");
            self.processing = false;
            return false;
        }
        
        var filter = JSON.parse(JSON.stringify(self.filter));
            filter.q = $("input[name=q]").val();
            filter.category = $("select[name=category]").val();

        var data = Object.keys(filter)
            .map(
                (key) =>
                encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
            )
            .join("&");

        authFetch("/report/rekonsiliasi/excell", {
            method: "POST",
            body: data,
        }).then((response) => response.blob())
        .then((blob) => {
        setTimeout(() => {
            self.processing = false;
        }, 1000);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "rekonsiliasi.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
        });
    },
  },
  mounted() {
    const e = this.$refs;
    const self = this;
    
    new Daterangepicker(
        this.$refs.daterange,
        {
            startDate: !this.dt1 ? moment().day(-31) : this.dt1,
            endDate: !this.dt2 ? moment() : this.dt2,
            locale: {
                format: 'DD/MM/YYYY'
            },
        },
        function (d1, d2) {
            self.dateStart = d1.format("YYYY-MM-DD");
            self.dateEnd = d2.format("YYYY-MM-DD");
            // self.data_type = self.data_type;
            // self.filter.datatype = self.data_type;
            self.filter.dt1 = self.dateStart;
            self.filter.dt2 = self.dateEnd;
            self.onDate();
        }
    );

    this.tbl = createTable(e.tblrekonsiliasi, {
      title: "",
      roles: this.$route.params.roles,
      selectedRowClass: "",
      serverSide: true,
      toolbarEl: e.toolbar,
      frame: false,
      displayLength: 10,
      lengthChange: true,
      stateSave: true,
      dom: "<'row'<'col-sm-12 col-md-6 btn-area-selected'><'col-sm-12 col-md-6'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-2'l><'col-sm-12 col-md-3'i><'col-sm-12 col-md-7'p>>",
      ajax: "/payment/rekonsiliasi",
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      order: [[ 1, "desc" ]],
      paramData: function (d) {
            var filter = JSON.parse(JSON.stringify(self.filter));
            for (const key in filter) {
                d[key] = filter[key] === false ? 0 : filter[key];
            }
        },
      columns: [
        { data: "reconciled_on",
        render: function (data, type, row, meta) {
          return !data ? '-' : moment(data).format("DD/MM/YYYY");
        } },
        { data: "updated_on",
        render: function (data, type, row, meta) {
          return moment(data).format("DD/MM/YYYY");
        } },
        { data: "no_resi"},
        { data: "grand_total", sortable: false, },
        { data: "nama" },
        { data: "nilai_cod" },
        { data: "nilai_ongkir" },
        { data: "item_value" },
        { data: "percent_cashback" },
        { data: "percent_shipper" },
        { data: "insurance_fee" },
        { data: "grand_total" },
        { data: "order_code" },
      ],
      filterBy: [12, 2, 4, 5, 11],
      rowCallback: function (row, data) {
        //inisiasi data
        let dataitemvalue = data.item_value;
        let datainsurance_fee = data.insurance_fee;
        let datainsurance_value = data.insurance_value;
        let datanilai_cod = data.nilai_cod;
        let datanilai_ongkir = data.nilai_ongkir;
        let percent_cashback = data.percent_cashback;
        let percent_shipper = data.percent_shipper;

        //to price
        let itemvalue = self.toPrice(dataitemvalue.split(".", 1));
        let insurancefee = self.toPrice(datainsurance_fee.split(".", 1));
        let insurancevalue = self.toPrice(datainsurance_value.split(".", 1));
        let cod = self.toPrice(datanilai_cod.split(".", 1));
        let ongkir = self.toPrice(datanilai_ongkir.split(".", 1));

        if (datanilai_cod != 0) {
          $("td:eq(5)", row).html("Rp " + cod);
        } else {
          $("td:eq(5)", row).html("Rp 0");
        }

        if (datanilai_ongkir != 0) {
          $("td:eq(6)", row).html("Rp " + ongkir);
        } else {
          $("td:eq(6)", row).html("Rp 0");
        }

        if (dataitemvalue != 0) {
          $("td:eq(7)", row).html("Rp " + itemvalue);
        } else {
          $("td:eq(7)", row).html("Rp 0");
        }

        if (percent_cashback != 0) {
          $("td:eq(8)", row).html(
            "<h6>" + percent_cashback.split(".", 1) + "%</h6>"
          );
        } else {
          $("td:eq(8)", row).html("<h6>0</h6>");
        }

        if (percent_shipper != 0) {
          $("td:eq(9)", row).html(
            "<h6>" + percent_shipper.split(".", 1) + "%</h6>"
          );
        } else {
          $("td:eq(9)", row).html("<h6>0</h6>");
        }

        if (datainsurance_fee != 0) {
          $("td:eq(10)", row).html("Rp " + insurancefee);
        } else {
          $("td:eq(10)", row).html("Rp 0");
        }

        if (data.grand_total > 0) {
          $("td:eq(11)", row).html("Rp " + formatCurrency(data.grand_total));
        } else {
          $("td:eq(11)", row).html("Rp 0");
        }

        if (data.reconciled == 1) {
            let title = !data.display_name ? 'system' : data.display_name;
          $("td:eq(3)", row).html("Done by " + title);
        } else {
          $("td:eq(3)", row).html(
            `<div class="btn-group">
            <button type="button" class="btn btn-sm btn-outline-info" data-action="confirm" data-id="` +
              data.order_code +
              `"><i class="fas fa-check"></i> Rekonsiliasi</button>
                </div>`
          );
        }
      },
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
    });
  },
};
</script>
<style scoped>
</style>